import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useMemo } from 'react';
import flatten from 'lodash/flatten';
import templateEngine, { firstTemplateVariableName } from '@/utils/templateEngine';
import { getColorAtPalletIndex } from '@/utils/colors';
import { flatObjectHash, selectionResultsToHash, seriesOptionsToHash, collectionOptionsToHash } from '@/utils/widget/hashing';
import { createTrendlineMap, appendTrendlineData, createTrendlineDataset } from '@/components/widget/utils/trendlines';
import { getDataLabelOptions } from '../../internal/utils';
import { sumBubbleRadius, scaleRadius } from './utils';
const CHARTJS_DEFAULT_TEMPLATES = {
  scatter: '({{x}}, {{y}})',
  bubble: '({{x}}, {{y}}, {{r}})'
};

const getTemplate = (chartType, dataPoint, ctx) => {
  const {
    globalSeriesOptions,
    options
  } = ctx || {};
  const defaultTemplate = (options === null || options === void 0 ? void 0 : options.template) || (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.template) || CHARTJS_DEFAULT_TEMPLATES[chartType];
  const varName = defaultTemplate ? firstTemplateVariableName(defaultTemplate) : ''; // Construct a template for each data point value
  // when only a single value is provided.
  // Note: This is a temporary solution that will be replaced by free-form templating.

  if (defaultTemplate && varName === 'value') {
    const pointTemplates = [];
    Object.keys(dataPoint).forEach(key => {
      // Replace the default value variable with the key
      pointTemplates.push(defaultTemplate.replace(/value/g, key));
    });
    return `(${pointTemplates.join(', ')})`;
  }

  return defaultTemplate;
};

export default function usePointChartData(_ref) {
  let {
    selectionResults,
    seriesOptions,
    globalSeriesOptions = {},
    isCategorical = false,
    colorPallet,
    groupOptions = [],
    collectionOptions = [],
    globalTrendline,
    xScaleMin,
    xScaleMax,
    seriesMinimumLength,
    chartType,
    themeStyles
  } = _ref;
  const seriesOptionsHash = seriesOptionsToHash(seriesOptions);
  const selectionResultsHash = selectionResultsToHash(selectionResults);
  const collectionOptionsHash = collectionOptionsToHash(collectionOptions);
  const globalSeriesOptionsHash = flatObjectHash(globalSeriesOptions || {});
  const globalTrendlineHash = flatObjectHash(globalTrendline || {});
  const colorPalletHash = colorPallet.join('');
  const updateHash = [selectionResultsHash, seriesOptionsHash, collectionOptionsHash, colorPalletHash, isCategorical ? 'categories' : 'points', chartType, globalTrendlineHash, globalSeriesOptionsHash, xScaleMin || '0', xScaleMax || '0'].join();
  const result = useMemo(() => {
    if (!selectionResults || !selectionResults.length) {
      return {
        chartData: {
          datasets: []
        },
        dataBoundaries: {
          xMin: 0,
          xMax: 0,
          yMin: 0,
          yMax: 0
        },
        updateHash: ''
      };
    }

    let axisTemplate = '';
    const isBubble = chartType === 'bubble';
    const trendlines = createTrendlineMap();
    const datasets = selectionResults.map(collection => collection.map(selectionResultGroup => {
      // Pair selection group with series options
      const options = seriesOptions[selectionResultGroup.selectionIndex] || {}; // Lookup any group options to
      // apply as the style defaults

      const groupOption = typeof options.groupOption === 'number' ? groupOptions[options.groupOption] || {} : {}; // Lookup any collection options to
      // apply as the style defaults

      const collectionOption = collectionOptions[selectionResultGroup.collectionIndex] || {}; // Use the first template found
      // as the template for the value axis

      const template = options.template || globalSeriesOptions.template;

      if (template && !axisTemplate) {
        axisTemplate = template;
      }

      return {
        selectionResults: selectionResultGroup,
        // Overwrite group options with selection's options
        options: _objectSpread(_objectSpread({}, groupOption), options),
        globalSeriesOptions,
        collectionOption
      };
    }) // Remove unrenderable results
    .filter(_ref2 => {
      let {
        selectionResults
      } = _ref2;
      return selectionResults.data.length >= seriesMinimumLength;
    }) // Filter collapsed
    .filter(_ref3 => {
      let {
        selectionResults,
        options = {}
      } = _ref3;

      // Filter out collapsed group
      // when all its' values are not truthy
      if (options.collapsible) {
        return selectionResults.data.some(item => Boolean(item.value));
      }

      return true;
    }) // Create series data
    .reduce((acc, _ref4) => {
      var _options$trendline, _collectionOption$tre;

      let {
        selectionResults,
        options,
        globalSeriesOptions,
        collectionOption
      } = _ref4;
      const userLabels = options.labels || {}; // Collect & parse selection group's
      // data point values

      const values = [selectionResults.data[0].value, selectionResults.data[1].value, isBubble ? selectionResults.data[2].value : undefined].filter(v => v !== undefined).map(v => parseFloat(`${v}`)); // Convert group series into:
      // consumable x, y, r values

      const dataPoint = {
        x: values[0],
        y: values[1]
      };

      if (isBubble) {
        dataPoint.r = values[2];
      } // Provide any custom labels for selection


      const customLabel = userLabels[selectionResults.data[0].labelCell || ''] || options.label || '';
      acc.titles.push(customLabel || selectionResults.data[0].label || collectionOption.label || ''); // Add selections as title fallback

      acc.selections.push(selectionResults.selections); // Add the selection index responsible
      // for this this item in the dataset

      acc.selectionIndexes.push(selectionResults.selectionIndex); // Provide default template

      const template = getTemplate(chartType, dataPoint, {
        options,
        globalSeriesOptions
      }); // Create optional custom
      // template for data point

      acc.templates.push(template ? templateEngine(template, dataPoint) : ''); // Add data point

      acc.data.push(dataPoint); // Add optional custom color
      // for the selection

      const color = options.color || '';
      acc.backgroundColor.push(color);
      acc.datalabels.push(getDataLabelOptions({
        value: dataPoint,
        backgroundColor: color,
        defaultTemplate: template || CHARTJS_DEFAULT_TEMPLATES[chartType],
        options,
        globalSeriesOptions,
        collectionOption
      })); // Append data to any configured trendline
      // options to be appended to chart data below

      if ((_options$trendline = options.trendline) !== null && _options$trendline !== void 0 && _options$trendline.display) {
        appendTrendlineData(trendlines, options.trendline, [dataPoint]);
      }

      if ((_collectionOption$tre = collectionOption.trendline) !== null && _collectionOption$tre !== void 0 && _collectionOption$tre.display) {
        appendTrendlineData(trendlines, collectionOption.trendline, [dataPoint]);
      }

      if (globalTrendline) {
        appendTrendlineData(trendlines, globalTrendline, [dataPoint]);
      } // Add optional custom point configuration
      // In order: Series options, Collection options, Global options


      acc.pointStyle.push(options.pointStyle ?? collectionOption.pointStyle ?? globalSeriesOptions.pointStyle ?? themeStyles.pointStyle ?? '');
      acc.pointRadius.push(options.pointRadius ?? collectionOption.pointRadius ?? globalSeriesOptions.pointRadius ?? themeStyles.pointRadius);
      acc.pointBackgroundColor.push(options.pointBackgroundColor ?? collectionOption.pointBackgroundColor ?? globalSeriesOptions.pointBackgroundColor ?? themeStyles.pointBackgroundColor ?? '');
      return acc;
    }, {
      data: [],
      selections: [],
      selectionIndexes: [],
      templates: [],
      titles: [],
      backgroundColor: [],
      datalabels: [],
      pointBackgroundColor: [],
      pointRadius: [],
      pointStyle: []
    })); // Assembled using categorical strategy
    // or by default selection/point strategy

    const chartData = {
      datasets: []
    }; // Convert each data point in each dataset
    // into a list of single value datasets

    if (!isCategorical && datasets.length) {
      chartData.datasets = flatten(datasets.map(dataset => dataset.data.map((dataPoint, index) => {
        const selectionIndex = dataset.selectionIndexes[index]; // Use selection's custom template,
        // 2nd fallback to Chart.js default

        const templates = [dataset.templates[index] || templateEngine(CHARTJS_DEFAULT_TEMPLATES[chartType], // Chart.js default
        dataPoint)]; // Use selection's custom background color,
        // 2nd fallback to color pallet's default color

        const backgroundColor = [dataset.backgroundColor[index] || getColorAtPalletIndex(selectionIndex, colorPallet)];
        return {
          label: dataset.titles[index],
          data: [dataPoint],
          titles: [dataset.titles[index]],
          templates,
          selection: dataset.selections[index],
          selectionIndex: selectionIndex,
          datalabels: [dataset.datalabels[index]],
          backgroundColor,
          pointStyle: dataset.pointStyle[index],
          pointRadius: dataset.pointRadius[index],
          pointBackgroundColor: dataset.pointBackgroundColor[index] || backgroundColor // borderColor: [],
          // hoverBackgroundColor: [],

        };
      })));
    } else {
      chartData.datasets = datasets.map((categorySelections, groupIndex) => {
        const collectionOption = collectionOptions[groupIndex] || {}; // Apply custom background color or use color pallet

        const backgroundColor = categorySelections.backgroundColor.map(backgroundColor => backgroundColor || collectionOption.color || getColorAtPalletIndex(groupIndex, colorPallet)); // Use selection's custom template,
        // 2nd use collection's template,
        // 3rd fallback to Chart.js default

        const templates = categorySelections.templates.map((template, colSelectionIndex) => template || templateEngine(CHARTJS_DEFAULT_TEMPLATES[chartType], categorySelections.data[colSelectionIndex])); // Use selection's custom title,
        // 2nd use collection's title,

        const selections = categorySelections.selections;
        const titles = categorySelections.titles.map((title, colSelectionIndex) => title || selections[colSelectionIndex]);
        return {
          label: collectionOption.label || `Category ${groupIndex + 1}`,
          data: categorySelections.data,
          templates,
          titles,
          backgroundColor,
          selections,
          selectionIndexes: categorySelections.selectionIndexes,
          datalabels: categorySelections.datalabels,
          pointStyle: categorySelections.pointStyle,
          pointRadius: categorySelections.pointRadius,
          pointBackgroundColor: categorySelections.pointBackgroundColor.map((pointBackgroundColor, idx) => pointBackgroundColor || backgroundColor[idx]) // borderColor: [],
          // hoverBackgroundColor: [],

        };
      });
    } // Calculate mins/maxs of all datasets


    const dataBoundaries = datasets.reduce((acc, dataset) => {
      const xValues = dataset.data.map(_ref5 => {
        let {
          x
        } = _ref5;
        return x;
      });
      const yValues = dataset.data.map(_ref6 => {
        let {
          y
        } = _ref6;
        return y;
      });
      acc.xMin = Math.min(acc.xMin, ...xValues);
      acc.xMax = Math.max(acc.xMax, ...xValues);
      acc.yMin = Math.min(acc.yMin, ...yValues);
      acc.yMax = Math.max(acc.yMax, ...yValues);
      return acc;
    }, {
      xMin: Infinity,
      xMax: -Infinity,
      yMin: Infinity,
      yMax: -Infinity
    }); // Scale bubble chart data radius to
    // a size proportional for the chart

    if (chartType === 'bubble') {
      const totalRadius = sumBubbleRadius(chartData);
      chartData.datasets.map(dataset => {
        return dataset.data.map(dataPoint => {
          dataPoint.r = scaleRadius({
            value: dataPoint.r,
            total: totalRadius
          });
          return dataPoint;
        });
      });
    }

    const minXAxis = typeof xScaleMin === 'number' ? xScaleMin : dataBoundaries.xMin;
    const maxXAxis = typeof xScaleMax === 'number' ? xScaleMax : dataBoundaries.xMax; // Append optional trendline datasets

    for (const [trendlineOptions, trendlineData] of trendlines) {
      if (trendlineData.length < 2) continue;
      const trendline = createTrendlineDataset(trendlineOptions, trendlineData, minXAxis, maxXAxis);

      if (trendline && trendline.display) {
        // @ts-ignore
        chartData.datasets.push(trendline);
      }
    }

    return {
      chartData,
      dataBoundaries,
      updateHash,
      axisTemplate
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateHash]);
  return result;
}