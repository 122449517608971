import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ThemeId, ThemeTag } from '@/interfaces/theme';
const commonStyles = {
  'rem-base': 16,
  radius: '0.25rem',
  'padding-0_5': '.125rem',
  // 2px
  'padding-1': '0.25rem',
  // global-xsm-padding 4px
  'padding-1_5': '.375rem',
  // 6px
  'padding-2': '.5rem',
  // 8px
  'padding-2_5': '.625rem',
  // global-sm-padding 10px
  'padding-3': '.75rem',
  // global-md-padding 12px
  'padding-4': '1rem',
  // global-padding
  'padding-5': '1.25rem',
  // global-lg-padding: 20px
  'padding-6': '1.5rem',
  // global-xlg-padding: 24px
  'padding-7': '1.75rem',
  // 28px
  'padding-8': '2rem',
  // global-xxlg-padding: 32px
  'padding-10': '2.5rem',
  // global-xxxlg-padding: 40px
  'padding-12': '3rem',
  // global-xxxxlg-padding: 48px
  'padding-16': '4rem',
  // global-xxxxxlg-padding: 64px
  // Common Colors
  primary: '#3259e8',
  'gray-50': '#f7f8fb',
  // $gray-5
  'gray-100': '#e2e5eb',
  // $gray-10
  'gray-200': '#c3c9d1',
  // $gray-20
  'gray-300': '#a2abb8',
  // $gray-30
  'gray-400': '#848e9e',
  // $gray-40
  'gray-500': '#626d7c',
  // $gray-50
  'gray-600': '#4c5563',
  // $gray-60
  'gray-700': '#343b45',
  // $gray-70
  'gray-800': '#22262e',
  // $gray-80
  'gray-900': '#151a1f',
  // $gray-90
  'gray-950': '#0e1217',
  // $gray-100
  // Typography
  'body-font-family': 'Manrope, sans-serif',
  'body-font-weight': 500,
  // medium
  'body-line-height': 1.5,
  'body-font-size': '1rem',
  'heading-font-family': 'Manrope, sans-serif',
  'heading-font-weight': 600,
  // semibold
  'heading-letter-spacing': 0,
  'heading-line-height': 1.2,
  'subheading-font-weight': 500,
  // medium
  'subheading-letter-spacing': 0,
  'subheading-line-height': 1.5,
  'big-font-size': '1.125rem',
  'big-line-height': '1.75rem',
  'small-font-size': '.875rem',
  'small-line-height': '1.25rem',
  'scrollbar-width': 'thin',
  'scrollbar-color': '#151a1f',
  // Charts
  'chart-axis-font-family': "Roboto Mono, ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace",
  'chart-axis-font-size': '.875rem',
  // 14px
  'chart-axis-font-color': '#626d7c',
  'chart-axis-font-weight': 300,
  'chart-bar-padding': '.9375rem',
  // 15px
  'chart-bar-top-left-radius': '.125rem',
  // 2px
  'chart-bar-bottom-left-radius': 0,
  'chart-bar-top-right-radius': '.125rem',
  'chart-bar-bottom-right-radius': 0
}; // Light theme styles

const lightStyles = _objectSpread(_objectSpread({}, commonStyles), {}, {
  secondary: '#e2e5eb',
  'body-background-color': '#ffffff',
  'border-color': '#c3c9d1',
  // Charts
  'chart-grid-color': '#c3c9d1',
  // Typography
  'body-font-color': '#000000',
  'muted-font-color': '#a2abb8',
  'heading-font-color': '#000000'
}); // Base colors for the theme
// from light to dark


const baseColors = ['#e2e5eb', '#c3c9d1', '#a2abb8', '#848e9e', '#626d7c', '#4c5563', '#343b45', '#22262e']; // Light theme color pallets

const lightThemeVariants = [{
  name: 'Default',
  colors: ['#3259e8', ...baseColors]
}, {
  name: 'Success',
  colors: ['#00823e', ...baseColors]
}, {
  name: 'Danger',
  colors: ['#c32518', ...baseColors]
}];

const darkStyles = _objectSpread(_objectSpread({}, commonStyles), {}, {
  secondary: '#ffffff',
  'body-background-color': '#000000',
  'border-color': '#22262e',
  // Charts
  'chart-grid-color': '#343b45',
  'chart-data-label-color': '#626d7c',
  // Typography
  'body-font-color': '#ffffff',
  'muted-font-color': '#626d7c',
  'heading-font-color': '#ffffff'
}); // Dark theme base colors
// from dark to light


const darkBaseColors = baseColors.slice().reverse(); // Dark theme color pallets

const darkThemeVariants = [{
  name: 'Default',
  colors: ['#3259e8', ...darkBaseColors]
}, {
  name: 'Success',
  colors: ['#00823e', ...darkBaseColors]
}, {
  name: 'Danger',
  colors: ['#c32518', ...darkBaseColors]
}]; // When you modify Manrope variants,
// update the font's ID in /config/googleFonts.ts
// to avoid loading the same font twice in the dev environment

export const fonts = [{
  fontName: 'Manrope',
  variants: [{
    italic: false,
    weight: 500
  }, {
    italic: false,
    weight: 600
  }, {
    italic: false,
    weight: 700
  }]
}, {
  fontName: 'Roboto Mono',
  // Used for charts
  variants: [{
    italic: false,
    weight: 300
  }, {
    italic: false,
    weight: 500
  }]
}];
export default {
  light: {
    id: ThemeId.UniversalDesignLight,
    name: 'Default',
    styles: lightStyles,
    colorPallets: lightThemeVariants,
    tags: [ThemeTag.Light],
    fonts: fonts
  },
  dark: {
    id: ThemeId.UniversalDesignDark,
    name: 'Default',
    styles: darkStyles,
    colorPallets: darkThemeVariants,
    tags: [ThemeTag.Dark],
    fonts: fonts
  }
};