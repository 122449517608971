import { useInterval } from 'react-use';
import { useState, useEffect, useCallback, useRef } from 'react';
import workbookStorage from '@/services/storage/workbooks';
import cdn from '@/services/cdn';
import { nowUnix } from '@/common/utils/date';
import { createWrappedError } from '@/utils/errors';
const PREFIX = 'common: components: reporting: Questonnaire: useLoadWorkbookData:';
const DELAY = 30000; // 30 seconds of delay

// If Any local workbook data provided, return
// it without remote loading. Otherwise load remote
// workbook data using provided url
export default function useLoadWorkbookData(_ref) {
  let {
    widgetId,
    workbookUrl = '',
    localWorkbookData,
    onError = () => null
  } = _ref;
  const isLoading = useRef(false);
  const hasLocalWorkbook = Boolean(localWorkbookData);
  const {
    0: remoteData,
    1: setRemoteData
  } = useState(null);
  const {
    0: currentWorkbookDigest,
    1: setCurrentWorkbookDigest
  } = useState('');
  const {
    0: latestWorkbookDigest,
    1: setLatestWorkbookDigest
  } = useState('');
  const {
    0: remoteWorkbookUpdatedAt,
    1: setRemoteWorkbookUpdatedAt
  } = useState(0); // Load workbook data JSON

  const fetchWorkbookData = useCallback(async () => {
    isLoading.current = true;

    try {
      const result = await workbookStorage.downloadWorkbook(workbookUrl, true);
      return {
        data: result.data,
        digest: result.digest
      };
    } catch (err) {
      // Wrap error
      throw createWrappedError(`${PREFIX} fetchWorkbookData: failed to load workbook data at "${workbookUrl}"`, err);
    } finally {
      setTimeout(() => isLoading.current = false);
    }
  }, [workbookUrl]); // Load workbook when workbook
  // URL is provided and no workbook
  // data available or outdated

  useEffect(() => {
    let remoteWasUpdatedAfterInitialLoad = false;
    if (hasLocalWorkbook || isLoading.current) return; // Skip if workbook unmodified

    if (currentWorkbookDigest && currentWorkbookDigest === latestWorkbookDigest) {
      return;
    } // Purge cache to permit fresh request
    // for newly discovered, latest workbook


    if (currentWorkbookDigest && currentWorkbookDigest !== latestWorkbookDigest) {
      workbookStorage.removeFromCache(workbookUrl);
      remoteWasUpdatedAfterInitialLoad = true;
    }

    fetchWorkbookData().then(result => {
      setRemoteData(result.data);

      if (remoteWasUpdatedAfterInitialLoad) {
        setRemoteWorkbookUpdatedAt(nowUnix());
      } // Ensure digest set after remote
      // data has been set and propagated


      setTimeout(() => {
        setCurrentWorkbookDigest(result.digest);
        setLatestWorkbookDigest(result.digest);
      });
    }).catch(err => {
      onError(createWrappedError(`${PREFIX} workbook data failed to load`, err));
    });
  }, [currentWorkbookDigest, latestWorkbookDigest, fetchWorkbookData, hasLocalWorkbook, workbookUrl, onError]);
  const isPolling = useRef(false); // Load widget polling JSON

  const fetchWidgetPollingData = useCallback(async () => {
    isPolling.current = true;

    try {
      return cdn.getCDNWidgetPolling(widgetId);
    } catch (err) {
      // Wrap error
      throw createWrappedError(`${PREFIX} fetchWidgetPollingData: failed to load widget polling data for widget "${widgetId}"`, err);
    } finally {
      isPolling.current = false;
    }
  }, [widgetId]); // Long poll for widget polling data
  // to publish latest workbook hash

  useInterval(() => {
    if (hasLocalWorkbook || isPolling.current) return;
    fetchWidgetPollingData().then(_ref2 => {
      let {
        workbookDataDigest
      } = _ref2;

      // Skip if workbook unmodified or
      // workbook data digest undiscovered
      if (!workbookDataDigest || workbookDataDigest === latestWorkbookDigest) {
        return;
      }

      setLatestWorkbookDigest(workbookDataDigest);
    }).catch(err => {
      onError(createWrappedError(`${PREFIX} widget polling data failed to load`, err));
    });
  }, !isPolling.current ? DELAY : null);
  return {
    data: localWorkbookData || remoteData || undefined,
    remoteWorkbookUpdatedAt,
    currentWorkbookDigest
  };
}