import templateEngine from '@/utils/templateEngine';
import { getDataLabelOptions } from '../../internal/utils'; // Create series data from group

export function createSeriesData(params) {
  const {
    selectionIndex,
    group,
    options,
    colorPallet,
    defaultColor = colorPallet[0],
    themeStyles
  } = params;
  const userLabels = options.labels || {};
  const collapsible = Boolean(options.collapsible);
  const hideLegend = typeof options.omitFromLegend === 'boolean' ? options.omitFromLegend : false; // Provide default template

  const template = options.template || `{{value}}`; // Convert each item in group
  // into a renderable item for
  // the visualization

  return group.data // Filter out individual falsey items
  .filter(item => collapsible ? Boolean(item.value) : true).map(item => {
    let value = parseFloat(`${item.value}`);
    value = isNaN(value) ? 0 : value;
    const customLabel = userLabels[item.labelCell || ''] || options.label || '';
    const color = options.pointBackgroundColor ?? defaultColor;
    const seriesData = {
      value,
      selection: group.selections,
      selectionIndex,
      hideLegend,
      name: customLabel || item.label || group.selections,
      template: templateEngine(template, {
        value: item.value
      }),
      pointBackgroundColor: color,
      pointStyle: options.pointStyle ?? 'circle',
      pointRadius: options.pointRadius ?? (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.pointRadius),
      datalabels: getDataLabelOptions({
        value,
        backgroundColor: color,
        defaultColor: themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.chartDataLabelColor,
        options
      })
    };
    return seriesData;
  });
} // Calculate the metrics for a line
// to determine its overall weight

export function calculateLineMetrics(data) {
  if (data.length === 0) return {
    weight: -Infinity
  }; // Calculate various metrics for the line

  const metrics = {
    max: Math.max(...data),
    min: Math.min(...data),
    last: data[data.length - 1],
    first: data[0],
    length: data.length,
    sum: data.reduce((acc, val) => acc + val, 0),
    mean: data.reduce((acc, val) => acc + val, 0) / data.length,
    // Average height of the line
    trend: 0,
    weight: 0
  }; // Measure the overall direction and rate of change
  // positive slope = ascending, negative = descending

  metrics.trend = data.length > 1 ? (data[data.length - 1] - data[0]) / (data.length - 1) : 0; // Calculate a weighted score for the line

  metrics.weight = metrics.mean * 0.35 + // Overall height
  metrics.last * 0.35 + // Recent values
  metrics.max * 0.2 + // Peak values
  metrics.trend * 0.1; // Direction

  return metrics;
}