import design from '@/config/design';
import templateEngine from '@/utils/templateEngine';
import { isDark } from '@/utils/colors'; // Filter out collapsed group
// when all its' values are not truthy

export function filterCollapsedGroups(group, options) {
  if (options.collapsible) {
    return group.data.some(item => Boolean(item.value));
  }

  return true;
} // Calculate min/max of all datasets

export function getDataBoundaries(datasets) {
  let stacked = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (stacked) {
    // Calculate min/max for stacked datasets
    // by summing up all values in each column
    const columnCount = datasets.reduce((acc, dataset) => {
      return Math.max(acc, dataset.data.length);
    }, 0);
    let min = 0;
    let max = 0;

    for (let i = 0; i < columnCount; i++) {
      // Sum up all values in the column
      const columnSum = datasets.reduce((sum, dataset) => sum + (dataset.data[i] || 0), 0);
      min = Math.min(min, columnSum);
      max = Math.max(max, columnSum);
    }

    return {
      min,
      max
    };
  }

  return datasets.reduce((acc, dataset) => {
    acc.min = Math.min(acc.min, ...dataset.data);
    acc.max = Math.max(acc.max, ...dataset.data);
    return acc;
  }, {
    min: Infinity,
    max: -Infinity
  });
} // Get data label color contrast to background color

export function getDataLabelColor(backgroundColor) {
  return isDark(backgroundColor) ? design.colors.white : design.colors.gray100;
}
export function getDefaultDataLabelOptions() {
  return {
    display: false,
    color: design.colors.gray100,
    align: 'center',
    anchor: 'end',
    rotation: 0,
    clamp: true,
    template: '0'
  };
} // Get data label options for each data point
// based on series and collection options

export function getDataLabelOptions(params) {
  var _globalSeriesOptions$, _options$dataLabels, _collectionOption$dat, _globalSeriesOptions$2, _options$dataLabels2, _collectionOption$dat2, _globalSeriesOptions$3, _options$dataLabels3, _collectionOption$dat3, _globalSeriesOptions$4, _options$dataLabels4, _collectionOption$dat4, _globalSeriesOptions$5, _options$dataLabels5, _collectionOption$dat5, _globalSeriesOptions$6, _options$dataLabels6, _collectionOption$dat6, _globalSeriesOptions$7, _options$dataLabels7, _collectionOption$dat7;

  const {
    value,
    backgroundColor,
    options,
    globalSeriesOptions = {},
    defaultColor,
    defaultTemplate,
    collectionOption = {}
  } = params;
  const defaultOptions = getDefaultDataLabelOptions(); // Get contrast color for data labels

  const dataLabelColor = ((_globalSeriesOptions$ = globalSeriesOptions.dataLabels) === null || _globalSeriesOptions$ === void 0 ? void 0 : _globalSeriesOptions$.color) ?? ((_options$dataLabels = options.dataLabels) === null || _options$dataLabels === void 0 ? void 0 : _options$dataLabels.color) ?? (collectionOption === null || collectionOption === void 0 ? void 0 : (_collectionOption$dat = collectionOption.dataLabels) === null || _collectionOption$dat === void 0 ? void 0 : _collectionOption$dat.color) ?? defaultColor ?? getDataLabelColor(backgroundColor); // Data label options

  const displayDataLabels = ((_globalSeriesOptions$2 = globalSeriesOptions.dataLabels) === null || _globalSeriesOptions$2 === void 0 ? void 0 : _globalSeriesOptions$2.display) ?? ((_options$dataLabels2 = options.dataLabels) === null || _options$dataLabels2 === void 0 ? void 0 : _options$dataLabels2.display) ?? ((_collectionOption$dat2 = collectionOption.dataLabels) === null || _collectionOption$dat2 === void 0 ? void 0 : _collectionOption$dat2.display) ?? defaultOptions.display;
  const alignment = ((_globalSeriesOptions$3 = globalSeriesOptions.dataLabels) === null || _globalSeriesOptions$3 === void 0 ? void 0 : _globalSeriesOptions$3.alignment) ?? ((_options$dataLabels3 = options.dataLabels) === null || _options$dataLabels3 === void 0 ? void 0 : _options$dataLabels3.alignment) ?? ((_collectionOption$dat3 = collectionOption.dataLabels) === null || _collectionOption$dat3 === void 0 ? void 0 : _collectionOption$dat3.alignment) ?? defaultOptions.align;
  const anchoring = ((_globalSeriesOptions$4 = globalSeriesOptions.dataLabels) === null || _globalSeriesOptions$4 === void 0 ? void 0 : _globalSeriesOptions$4.anchoring) ?? ((_options$dataLabels4 = options.dataLabels) === null || _options$dataLabels4 === void 0 ? void 0 : _options$dataLabels4.anchoring) ?? ((_collectionOption$dat4 = collectionOption.dataLabels) === null || _collectionOption$dat4 === void 0 ? void 0 : _collectionOption$dat4.anchoring) ?? defaultOptions.anchor;
  const rotation = ((_globalSeriesOptions$5 = globalSeriesOptions.dataLabels) === null || _globalSeriesOptions$5 === void 0 ? void 0 : _globalSeriesOptions$5.rotation) ?? ((_options$dataLabels5 = options.dataLabels) === null || _options$dataLabels5 === void 0 ? void 0 : _options$dataLabels5.rotation) ?? ((_collectionOption$dat5 = collectionOption.dataLabels) === null || _collectionOption$dat5 === void 0 ? void 0 : _collectionOption$dat5.rotation) ?? defaultOptions.rotation;
  const clamping = ((_globalSeriesOptions$6 = globalSeriesOptions.dataLabels) === null || _globalSeriesOptions$6 === void 0 ? void 0 : _globalSeriesOptions$6.clamping) ?? ((_options$dataLabels6 = options.dataLabels) === null || _options$dataLabels6 === void 0 ? void 0 : _options$dataLabels6.clamping) ?? ((_collectionOption$dat6 = collectionOption.dataLabels) === null || _collectionOption$dat6 === void 0 ? void 0 : _collectionOption$dat6.clamping) ?? defaultOptions.clamp; // Provide default template

  const dataLabelTemplate = ((_globalSeriesOptions$7 = globalSeriesOptions.dataLabels) === null || _globalSeriesOptions$7 === void 0 ? void 0 : _globalSeriesOptions$7.template) ?? ((_options$dataLabels7 = options.dataLabels) === null || _options$dataLabels7 === void 0 ? void 0 : _options$dataLabels7.template) ?? ((_collectionOption$dat7 = collectionOption.dataLabels) === null || _collectionOption$dat7 === void 0 ? void 0 : _collectionOption$dat7.template) ?? options.template ?? defaultTemplate ?? '{{value}}';
  return {
    display: displayDataLabels,
    color: dataLabelColor,
    align: alignment,
    anchor: anchoring,
    rotation: rotation,
    clamp: clamping,
    template: templateEngine(dataLabelTemplate, typeof value === 'number' ? {
      value: value
    } : value)
  };
} // Extract data label property from context
// with default value

export function extractDataLabelProperty(context, property, defaultValue) {
  var _context$dataset$data;

  const dataLabel = (_context$dataset$data = context.dataset.datalabels) === null || _context$dataset$data === void 0 ? void 0 : _context$dataset$data[context.dataIndex];
  if (!dataLabel) return defaultValue;
  return dataLabel[property] ?? defaultValue;
} // Get the canvas element selector
// within the section

export function getSectionCanvasId(widgetId, sectionId) {
  return `${widgetId}-${sectionId}-canvas`;
}