// Disallowed characters for number input
// e, E - scientific notation
const DISABLED_NUMBER_INPUT_CHARACTERS = ['e', 'E', '-', '+', '.', ',']; // Check if the key is a valid number input key

export const isValidNumberInputKey = key => {
  return !DISABLED_NUMBER_INPUT_CHARACTERS.includes(key);
}; // Convert string to integer and
// remove all non-numeric characters

export const strToInteger = str => {
  const result = parseInt(str.replace(/[^0-9-]/g, ''), 10);
  return isNaN(result) ? 0 : result;
}; // Determine if a value is a number

export const isValidNumber = value => {
  return typeof value === 'number' && !isNaN(value);
}; // Returns the next index

export function createNextIndexGenerator() {
  let index = 0;
  return () => index++;
}