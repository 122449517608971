import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { merge, camelCase } from 'lodash';
import { useMemo } from 'react';
import { ThemeId } from '@/interfaces/theme';
import design, { remToPx } from '@/config/design';
import { deleteUndefinedValues } from '@/utils/objects';
import { useTheme } from '@/components/widget/Layout/ThemeProvider';
const defaultStyles = Object.freeze({
  chartBackgroundColor: design.colors.white,
  arcEmptyColor: design.colors.gray100,
  pointerColor: design.colors.gray90,
  titleFontWeight: 500,
  titleFontSize: 32,
  arcWidth: 0.3,
  gaugeInnerMargin: {
    top: 0.1,
    bottom: 0.0,
    left: 0.07,
    right: 0.07
  }
});
const shadcnDefaultStyles = {
  titleFontWeight: 700,
  titleFontSize: 24,
  arcWidth: 0.35,
  gaugeInnerMargin: {
    top: 0.09,
    bottom: 0.0,
    left: 0.07,
    right: 0.07
  }
};
const styleOverridesByTheme = Object.freeze({
  [ThemeId.UniversalDesignLight]: {
    arcEmptyColor: '#ebecf3',
    titleColor: design.colors.gray100
  },
  [ThemeId.UniversalDesignDark]: {
    arcEmptyColor: '#1d2127',
    titleColor: design.colors.white
  },
  [ThemeId.ShadcnLight]: _objectSpread(_objectSpread({}, shadcnDefaultStyles), {}, {
    arcEmptyColor: '#f4f4f5'
  }),
  [ThemeId.ShadcnDark]: _objectSpread(_objectSpread({}, shadcnDefaultStyles), {}, {
    arcEmptyColor: '#27272a'
  })
});
export const getThemeDefaults = params => {
  return merge({}, defaultStyles, params.theme ? styleOverridesByTheme[params.theme] : {}, mapThemeStylesToSectionStyles(params.styles || {}));
};

const mapConfigToSectionStyles = config => deleteUndefinedValues({
  chartBackgroundColor: config.chartBackground
});

const mapThemeStylesToSectionStyles = styles => {
  // Convert the theme style keys to camelCase
  const themeStyles = Object.keys(styles).reduce((acc, key) => {
    acc[camelCase(key)] = styles[key];
    return acc;
  }, {});
  const smallFontSize = remToPx(themeStyles.smallFontSize);
  const chartAxisFontSize = remToPx(themeStyles.chartAxisFontSize);
  return deleteUndefinedValues(_objectSpread(_objectSpread({}, themeStyles), {}, {
    chartBackgroundColor: styles['body-background-color'],
    chartAxisFontSize: !isNaN(chartAxisFontSize) ? chartAxisFontSize : undefined,
    legendFontFamily: themeStyles.bodyFontFamily,
    legendFontSize: !isNaN(smallFontSize) ? smallFontSize : undefined,
    legendLineHeight: themeStyles.bodyLineHeight,
    legendColor: themeStyles.bodyFontColor,
    titleFontFamily: themeStyles.bodyFontFamily,
    titleLineHeight: themeStyles.bodyLineHeight
  }));
};

export const useGaugeChartStyles = config => {
  const themeCtx = useTheme();
  const defaultStyles = useMemo(() => getThemeDefaults({
    theme: themeCtx.theme,
    styles: themeCtx.styles
  }), [themeCtx]); // The merge helper mutates the first object,
  // so we use an empty object to keep the default styles safe.
  // Merge styles in order: Default -> Defaults by Theme -> Theme Styles -> Config

  const themeStyles = useMemo(() => merge({}, defaultStyles, mapConfigToSectionStyles(config)), [config, defaultStyles]);
  return {
    themeStyles,
    defaultStyles,
    themeCtx
  };
};