export let ThemeId;

(function (ThemeId) {
  ThemeId["UniversalDesignLight"] = "universal-design-light";
  ThemeId["UniversalDesignDark"] = "universal-design-dark";
  ThemeId["ShadcnLight"] = "shadcn-light";
  ThemeId["ShadcnDark"] = "shadcn-dark";
})(ThemeId || (ThemeId = {}));

export let ThemeTag;

(function (ThemeTag) {
  ThemeTag["Light"] = "light";
  ThemeTag["Dark"] = "dark";
})(ThemeTag || (ThemeTag = {}));