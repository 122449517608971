import flatten from 'lodash/flatten';
import { filterCollapsedGroups, getDataBoundaries } from '../../internal/utils';
import { addColorsToDataset, fillDataset } from '../../internal/LineChart/utils';
import { createSeriesData } from './utils';
export function createData(_ref) {
  let {
    results,
    seriesOptions,
    globalSeriesOptions = {},
    colorPallet,
    chartType,
    legends = [],
    areaChartDefaultFill,
    themeStyles
  } = _ref;
  let valueAxisTemplate = '';
  const pairs = flatten(results).map(selectionResultGroup => {
    const {
      selectionIndex
    } = selectionResultGroup;
    const options = Object.assign({}, globalSeriesOptions, seriesOptions[selectionIndex] || {}); // Use the first template found
    // as the template for the value axis

    if (options.template && !valueAxisTemplate) {
      valueAxisTemplate = options.template;
    }

    return {
      group: selectionResultGroup,
      options,
      selectionIndex
    };
  }) // Filter collapsed
  .filter(_ref2 => {
    let {
      group,
      options
    } = _ref2;
    return filterCollapsedGroups(group, options);
  }) // Create series data
  .map(_ref3 => {
    let {
      group,
      options,
      selectionIndex
    } = _ref3;
    return createSeriesData({
      group,
      options,
      selectionIndex,
      colorPallet,
      themeStyles
    });
  }); // Create default dataset

  const labels = [];
  const dataset = {
    data: [],
    backgroundColor: [],
    pointBackgroundColor: [],
    pointRadius: [],
    pointStyle: [],
    borderColor: [],
    templates: [],
    titles: [],
    selections: [],
    selectionIndexes: [],
    datalabels: [],
    label: legends[0] || 'Legend 1',
    borderWidth: (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineWidth) ?? themeStyles.lineWidth,
    borderDash: (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineDash) ?? themeStyles.lineDash
  };
  flatten(pairs).forEach(seriesData => {
    fillDataset(seriesData, dataset, labels);
  });
  addColorsToDataset({
    dataset: dataset,
    borderColor: (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineColor) ?? (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.lineColor) ?? colorPallet[0],
    backgroundColor: (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineBackgroundColor) ?? (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.lineBackgroundColor),
    fill: chartType === 'vertical' ? 'start' : 'origin',
    areaChartDefaultFill,
    themeStyles
  });
  const datasets = [dataset]; // Calculate min/max

  const dataBoundaries = getDataBoundaries(datasets);
  return {
    legendsCount: 1,
    labels: labels,
    datasets: datasets,
    dataBoundaries: dataBoundaries,
    valueAxisTemplate
  };
}