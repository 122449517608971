import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { createTrendlineDataset } from '@/components/widget/utils/trendlines';
import { getDefaultDataLabelOptions } from '../utils'; // Check is grouped strategy

export function checkIsGrouped(selectionsStrategy, results) {
  return ['groups', 'stacked'].includes(selectionsStrategy) || results.length > 1;
} // Fill dataset with series data

export function fillDataset(data, dataset, labels) {
  const {
    value,
    selection,
    selectionIndex,
    name,
    template,
    color
  } = data;
  if (labels) labels.push(name);

  if (data.datalabels) {
    dataset.datalabels.push(data.datalabels);
  }

  dataset.data.push(value);
  dataset.backgroundColor.push(color);
  dataset.templates.push(template);
  dataset.titles.push(name);
  dataset.selections.push(selection);
  dataset.selectionIndexes.push(selectionIndex);
  dataset.borderColor.push(data.borderColor);
  dataset.borderWidth.push(data.borderWidth);
} // Fill dataset with empty data

export function fillEmptyDataset(dataset) {
  if (dataset.datalabels) {
    dataset.datalabels.push(getDefaultDataLabelOptions());
  }

  dataset.data.push(0);
  dataset.templates.push('');
  dataset.titles.push('');
  dataset.selections.push('');
  dataset.selectionIndexes.push(-1);
} // Append trendline data to datasets

export function appendTrendlineDataset(datasets, trendlines, dataBoundaries) {
  for (const [trendlineOptions, trendlineData] of trendlines) {
    if (trendlineData.length < 2) continue;
    const trendline = createTrendlineDataset(trendlineOptions, trendlineData, dataBoundaries.min, dataBoundaries.max);

    if (trendline && trendline.display) {
      datasets.push(_objectSpread(_objectSpread({}, trendline), {}, {
        data: trendline.data.map(item => item.x)
      }));
    }
  }
} // Flattens the data from the datasets into a string

export function dataToString(datasets) {
  return joinNewLine(datasets.map(dataset => joinNewLine(dataset.data.map((item, idx) => `${dataset.titles[idx]} => ${item}`))));
} // Converts the data from the datasets into a string array

export function dataToStringArr(datasets) {
  return datasets.map(dataset => joinNewLine(dataset.data.map((item, idx) => `${dataset.titles[idx]} => ${item}`)));
}
export function joinNewLine(arr) {
  return arr.join('\n');
}
export function getBorderRadiusFromConfig(config) {
  return {
    topLeft: config.barRadiusTopLeft ?? 0,
    topRight: config.barRadiusTopRight ?? 0,
    bottomLeft: config.barRadiusBottomLeft ?? 0,
    bottomRight: config.barRadiusBottomRight ?? 0
  };
}