import isObject from 'lodash/isObject';
import { deepArrayMap } from '@/utils/array'; // Convert a selection result collection into
// a hash that can be used to regenerate
// visualization data

export const selectionResultsToHash = results => deepArrayMap(results, value => `${value.selections}${value.selectionIndex}${value.data.map(datum => flatObjectHash(datum)).join('')}`).join('|'); // Convert series options into a
// hash for checking if visualizations
// need re-rendering

export const seriesOptionsToHash = seriesOptions => seriesOptions.reduce((acc, option) => {
  acc += Object.entries(option).map(_ref => {
    let [k, v] = _ref;

    if (typeof v === 'object') {
      return `${k}${JSON.stringify(v)}`;
    }

    return `${k}${v}`;
  }).join('');
  return acc;
}, ''); // Convert collection options into a
// hash for checking if visualizations
// need re-rendering

export const collectionOptionsToHash = seriesOptions => seriesOptions.reduce((acc, option) => {
  acc += Object.entries(option).map(_ref2 => {
    let [k, v] = _ref2;

    if (typeof v === 'object') {
      return `${k}${JSON.stringify(v)}`;
    }

    return `${k}${v}`;
  }).join('');
  return acc;
}, ''); // Convert group options into a
// hash for checking if visualization
// should be re-rendered

export const groupOptionsToHash = collectionOptions => collectionOptions.reduce((acc, option) => {
  acc += flatObjectHash(option);
  return acc;
}, ''); // Convert a flat object into a hash string

export const flatObjectHash = obj => Object.entries(obj).map(_ref3 => {
  let [k, v] = _ref3;
  return `${k}${isObject(v) ? flatObjectHash(v) : v}`;
}).join(''); // NOTE: stringify is not 100% canoncial
// maybe switch to some consistent hasher:
// https://github.com/oyamist/merkle-json

export const widgetToHash = widget => JSON.stringify(widget, null, 0);