import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ThemeId, ThemeTag } from '@/interfaces/theme';
const commonStyles = {
  'rem-base': 16,
  radius: '0.5rem',
  'padding-0_5': '.125rem',
  'padding-1': '0.25rem',
  // global-xsm-padding: 4px
  'padding-1_5': '.375rem',
  // 6px
  'padding-2': '.5rem',
  // 8px
  'padding-2_5': '.625rem',
  // global-sm-padding: 10px
  'padding-3': '.75rem',
  // global-md-padding: 12px
  'padding-4': '1rem',
  // global-padding: 16px
  'padding-5': '1.25rem',
  // global-lg-padding: 20px
  'padding-6': '1.5rem',
  // global-xlg-padding: 24px
  'padding-7': '1.75rem',
  // 28px
  'padding-8': '2rem',
  // global-xxlg-padding: 32px
  'padding-10': '2.5rem',
  // global-xxxlg-padding: 40px
  'padding-12': '3rem',
  // global-xxxxlg-padding: 48px
  'padding-16': '4rem',
  // global-xxxxxlg-padding: 64px
  // Common Colors
  'gray-50': '#f9fafb',
  // $gray-5
  'gray-100': '#f3f4f6',
  // $gray-10
  'gray-200': '#e5e7eb',
  // $gray-20
  'gray-300': '#d1d5db',
  // $gray-30
  'gray-400': '#9ca3af',
  // $gray-40
  'gray-500': '#6b7280',
  // $gray-50
  'gray-600': '#4b5563',
  // $gray-60
  'gray-700': '#374151',
  // $gray-70
  'gray-800': '#1f2937',
  // $gray-80
  'gray-900': '#111827',
  // $gray-90
  'gray-950': '#030712',
  // $gray-100
  // Typography
  'body-font-family': 'Geist,ui-monospace,SFMono-Regular,Roboto Mono,Menlo,Monaco,Liberation Mono,DejaVu Sans Mono,Courier New,monospace',
  'body-font-weight': 500,
  // light
  'body-line-height': 1.5,
  'body-font-size': '1rem',
  'heading-font-family': 'Geist,ui-monospace,SFMono-Regular,Roboto Mono,Menlo,Monaco,Liberation Mono,DejaVu Sans Mono,Courier New,monospace',
  'heading-font-weight': 700,
  // bold
  'heading-letter-spacing': '-.05em',
  'heading-line-height': '2rem',
  'subheading-font-weight': 600,
  // semibold
  'subheading-letter-spacing': '-.025em',
  'subheading-line-height': '1rem',
  'big-font-size': '1.125rem',
  'big-line-height': '1.75rem',
  'small-font-size': '.875rem',
  'small-line-height': '1.25rem',
  'scrollbar-width': 'thin',
  // Charts
  'chart-axis-font-family': 'Geist,ui-monospace,SFMono-Regular,Roboto Mono,Menlo,Monaco,Liberation Mono,DejaVu Sans Mono,Courier New,monospace',
  'chart-axis-font-size': '.75rem',
  // 12px
  'chart-axis-font-weight': 500,
  'chart-bar-padding': '.625rem',
  'chart-bar-top-left-radius': '.5rem',
  'chart-bar-bottom-left-radius': '.5rem',
  'chart-bar-top-right-radius': '.5rem',
  'chart-bar-bottom-right-radius': '.5rem'
}; // Light theme styles

const lightStyles = _objectSpread(_objectSpread({}, commonStyles), {}, {
  primary: '#1c1c1e',
  secondary: '#F4F4F5',
  'body-background-color': '#ffffff',
  'border-color': '#e4e4e7',
  'scrollbar-color': '#e4e4e7',
  'chart-grid-color': '#f1f1f3',
  'chart-axis-font-color': '#6e6e77',
  'chart-data-label-color': '#09090b',
  // Typography
  'body-font-color': '#09090b',
  'muted-font-color': '#71717A',
  'heading-font-color': '#09090b'
}); // Light theme color pallets


const lightThemeVariants = [{
  name: 'Default',
  colors: ['#299d90', '#e76e50', '#264753', '#e9c469', '#f4a362']
}, {
  name: 'Palette',
  colors: ['#e76e50', '#299d90', '#264753', '#e9c469', '#f4a362']
}, {
  name: 'Sapphire',
  colors: ['#2463eb', '#60a8fa', '#3a87f7', '#91c6fe', '#bddcfe']
}, {
  name: 'Ruby',
  colors: ['#e21d48', '#fbd5da', '#f17e92', '#f7abb6', '#e9536f', '#e9536f']
}, {
  name: 'Emerald',
  colors: ['#125427', '#1dc355', '#098637', '#113b1d', '#0e2014']
}, {
  name: 'Daylight',
  colors: ['#60432f', '#765337', '#8f633d', '#ad7c48', '#bb9054']
}, {
  name: 'Midnight',
  colors: ['#e6e5e5', '#bcbcbd', '#939395', '#6a6a6c', '#414144']
}];

const darkStyles = _objectSpread(_objectSpread({}, commonStyles), {}, {
  primary: '#fafafa',
  secondary: '#27272a',
  'body-background-color': '#09090b',
  'border-color': '#27272a',
  'scrollbar-color': '#27272a',
  'chart-grid-color': '#18181a',
  'chart-axis-font-color': '#a1a1aa',
  'chart-data-label-color': '#fafafa',
  // Typography
  'body-font-color': '#fafafa',
  'muted-font-color': '#a1a1aa',
  'heading-font-color': '#fafafa'
}); // Light theme color pallets


const darkThemeVariants = [{
  name: 'Default',
  colors: ['#2662d9', '#e23670', '#e88c30', '#af57db', '#2eb88a']
}, {
  name: 'Palette',
  colors: ['#2662d9', '#e23670', '#e88c30', '#af57db', '#e23670']
}, {
  name: 'Sapphire',
  colors: ['#2563eb', '#60a8fb', '#3b86f7', '#90c7fe', '#bedcfe']
}, {
  name: 'Ruby',
  colors: ['#e21d48', '#e9536f', '#f17e92', '#f7abb6', '#fbd5da', '#fbd5da']
}, {
  name: 'Emerald',
  colors: ['#098637', '#125427', '#106a2e', '#113b1d', '#0e2014']
}, {
  name: 'Daylight',
  colors: ['#60432f', '#765337', '#8f633d', '#ad7c48', '#bb9054']
}, {
  name: 'Midnight',
  colors: ['#e6e5e5', '#bcbcbd', '#939395', '#6a6a6c', '#414144']
}]; // Theme Fonts

const fonts = [{
  fontName: 'Geist',
  variants: [{
    italic: false,
    weight: 500
  }, {
    italic: false,
    weight: 700
  }]
}];
export default {
  light: {
    id: ThemeId.ShadcnLight,
    name: 'Shadcn',
    styles: lightStyles,
    colorPallets: lightThemeVariants,
    tags: [ThemeTag.Light],
    fonts: fonts
  },
  dark: {
    id: ThemeId.ShadcnDark,
    name: 'Shadcn',
    styles: darkStyles,
    colorPallets: darkThemeVariants,
    tags: [ThemeTag.Dark],
    fonts: fonts
  }
};