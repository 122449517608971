import { useMemo } from 'react';
import { getEditingAreaAttr, getEditingAreaMetaDataAttr } from '@/components/Editor/hooks/useEditingAreas';
// Generate Editor mode attributes for loader element(s)
export default function useLoaderEditingAttrs() {
  let editing = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  const editingAttrs = useMemo(() => {
    if (!editing) return {};
    return {
      [getEditingAreaAttr()]: 'loading',
      [getEditingAreaMetaDataAttr()]: JSON.stringify({
        selection: 'missing',
        selectionIndex: -1
      })
    };
  }, [editing]);
  return editingAttrs;
}